@use 'src/src/app/scss/abstracts' as *;

.main-page {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  padding-bottom: 80px;

  @include mq($until: md) {
    padding-bottom: 40px;
  }

  &__games {
    &:not(:last-child) {
      margin-bottom: 20px;

      @include mq($until: md) {
        margin-bottom: 15px;
      }
    }
  }

  &__games-card {
    &:not(:last-child) {
      margin-bottom: 20px;

      @include mq($until: md) {
        margin-bottom: 15px;
      }
    }
  }

  &__games-title {
    text-align: center;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;

    @include mq($until: md) {
      margin-bottom: 40px;
    }
  }

  &__goods {
    margin-top: 70px;

    @include mq($until: md) {
      margin-top: 40px;
    }
  }

  &__goods-title {
    text-align: center;
    margin-bottom: 45px;

    @include mq($until: md) {
      margin-bottom: 30px;
    }
  }

  &__slider {
    margin-bottom: 60px;

    @include mq($until: md) {
      margin-bottom: 40px;
    }
  }

  &__highlights {
    margin-top: 30px;
    margin-bottom: 60px;

    @include mq($until: md) {
      margin-bottom: 50px;
    }
  }

  &__highlights-inner {
    padding-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    background: linear-gradient(2deg, rgb(118 69 255 / 70%) 1.32%, rgb(11 0 30 / 70%) 31.56%);
    border-radius: $border-radius-s;
    padding-bottom: 35px;

    @include mq($until: md) {
      padding-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &__highlights-title {
    text-align: center;
    margin-bottom: 50px;

    @include mq($until: md) {
      margin-bottom: 30px;
    }
  }
}
